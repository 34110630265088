

#card-container {
    display: grid;
    gap: 20px;
}

@media (min-width: 1000px) {
    #card-container {
        grid-template-columns: 1fr 1fr;
    }
}