.rental-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-content: start;
}

@media (min-width: 1000px) {
    .rental-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 50px;
    }     
}